'use client';

import { Button } from '@mui/material';
import { FlexBox, FlexRowCenter } from '@web/components/flex-box';
import SEO from '@web/components/SEO';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { Paragraph } from '@web/components/Typography';
import DumbLink from '@web/components/DumbLink';

export default function NotFound() {
  const router = useRouter();
  const handleGoBack = () => router.back();
  return (
    <FlexRowCenter px={2} minHeight="100vh" flexDirection="column">
      <SEO
        title="Oops! Page not found"
        description="We're sorry, but the page you requested could not be found. Please check the URL, or return to the home page."
      />

      <Image
        src="/assets/images/404.svg"
        alt="404 Error"
        width={320}
        height={320}
      />

      <Paragraph>The page you are looking for does not exist.</Paragraph>
      <FlexBox flexWrap="wrap">
        <Button
          variant="outlined"
          color="primary"
          sx={{
            m: 1,
          }}
          onClick={handleGoBack}
        >
          Go Back
        </Button>

        <DumbLink href="/" passHref>
          <Button
            variant="contained"
            color="primary"
            sx={{
              m: 1,
            }}
          >
            Continue Shopping at BuyWisely
          </Button>
        </DumbLink>
      </FlexBox>
    </FlexRowCenter>
  );
}
